import IGC from "./controllers/IGC";

export const STORE = {
  loadingIgcs: [],
  flights: [],
  igcs: [],
  countInZone: undefined,
  map: null,
  zone: null,
  zoneFeature: null,
  zoneModifier: null,
  tracksVectorSource: null,
  tracksVectorLayer: null,
  sitesSource: null,
  sitesLayer: null,
  point: null,
  line: null,
  withTakeOffs: false,
  igcFormat: new IGC(),
  ctxBlend: null,
  backValues: { mc: null, mz: null, zc: null, zr: null },
  isZoneGrabber: false,
  resolveDialog: null,
};
