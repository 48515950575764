import Dialog from "../components/Dialog.vue";
import vue from "vue";
import { STORE } from "..";

const dialog = ({ title, msg }) => {
  return new Promise((resolve) => {
    var DialogInstance = vue.extend(Dialog);
    var component = new DialogInstance({
      propsData: {
        title,
        msg,
        retval: 0,
      },
    }).$mount();

    var dialogEl = document.getElementById("loadMoreDialog");
    dialogEl.appendChild(component.$el);
    STORE.resolveDialog = resolve;

    component.$watch("retval", function (newVal) {
      STORE.isLoadMoreDialog = false;
      resolve({ shouldLoadMore: newVal, dialogEl });
    });
  }).then(({ shouldLoadMore, dialogEl }) => {
    dialogEl.firstChild.remove();
    STORE.resolveDialog = null;
    return shouldLoadMore;
  });
};

export default dialog;
