import axios from "axios";
import dayjs from "dayjs";
import { STORE } from "..";
import store, { MUTATIONS } from "../store";
import { getFormatFromUnix, getFromTo, stringToColour } from "./helpers";
// import { mockFlights } from "./mock";
import { getIgcs, resetTracks } from "./tracks";
import { PKEYS, setURLParams } from "./urlParams";

export const getFlights = async ({ from, ...params }) => {
  store.commit(MUTATIONS.setLoadingFlights, true);
  const queryParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc = `${acc}&${key}=${value}`;
    }
    return acc;
  }, "");

  try {
    const flights = await axios.get(
      `${process.env.VUE_APP_API_URL}/export?from=${from}${queryParams}`
    );
    store.commit(MUTATIONS.setLoadingFlights, false);
    return flights.data;
  } catch (error) {
    console.log("ERROR", { error });
    store.commit(MUTATIONS.setLoadingFlights, false);
    return [];
  }
};

const formatFlights = ({ flights }) => {
  return flights.map((flight) => {
    const nomSplit = flight.pilotFullName.split(" ");
    return {
      ...flight,
      date: dayjs(flight.date).format("YYMMDD"),
      dateShort: dayjs(flight.date).format("DD/MM/YY"),
      distance: flight.distance.toFixed(2),
      speed: flight.speed.toFixed(2),
      pilotInit:
        nomSplit[0].substring(0, 1) +
        nomSplit[nomSplit.length - 1].substring(0, 1),
      isMarcheVol: false,
      color: stringToColour(flight.pilotFullName),
      pilotLastName: flight.pilotFullName.split(" ")[1],
    };
  });
};

export const search = async ({ shouldReset = true } = {}) => {
  const {
    dateEl,
    deltaEl,
    departementEl,
    distanceMinEl,
    distanceMaxEl,
    gliderEl,
    //flightTypeEl,
  } = store.state.dom;
  const { from, to } = getFromTo();
  setURLParams({ key: PKEYS.date, value: dateEl });
  setURLParams({ key: PKEYS.delta, value: deltaEl });
  shouldReset && resetTracks();

  // const flights = mockFlights.slice(0, 30);
  // STORE.flights = formatFlights({ flights, from: 1623708000, durationEl });

  const flights = await getFlights({
    from: getFormatFromUnix(from).readL,
    to: getFormatFromUnix(to).readL,
    minPoints: distanceMinEl,
    minDist: distanceMinEl,
    maxDist: distanceMaxEl,
    departement: departementEl,
    gliderCats: gliderEl.join(","),
    //flightType: flightTypeEl,
  });
  //console.log("##CAP##", { flights });
  if (flights === "error") {
    STORE.flights = "error";
  } else {
    STORE.flights = formatFlights({ flights, from });
  }
  store.commit(MUTATIONS.setFlights, STORE.flights);
  // console.log("##CAP##", STORE.flights);
  //console.log("##CAP##", JSON.stringify(flights));
  getIgcs();
};
