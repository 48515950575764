import { Feature } from "ol";
import { getBottomLeft, getTopLeft, getTopRight } from "ol/extent";
import Circle from "ol/geom/Circle";
import { Modify } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, transformExtent } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { getDistance } from "ol/sphere";
import { Fill } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { STORE } from "..";
import { config } from "../config";
import store, { MUTATIONS } from "../store";
import { removeAllThermals } from "./analyse";
import { gtagEvent, passFiltering } from "./helpers";
import { getMapExt } from "./map";
import { getTakeOffMarkers } from "./markers";
import { resetPilots } from "./pilots";
import { clearTimePoints, resetTime } from "./replay";
import { addTrack } from "./tracks";
import { PKEYS, setURLParams } from "./urlParams";

export const hideZone = () => {
  STORE.zoneFeature.setStyle(new Style({}));
  STORE.map.removeInteraction(STORE.zoneModifier);
};

export const showZone = () => {
  STORE.zoneFeature.setStyle(null);
  STORE.map.addInteraction(STORE.zoneModifier);
};

export const buildZone = () => {
  const centerLongitudeLatitude = fromLonLat(config.zoneLngLat);
  const geometry = new Circle(centerLongitudeLatitude, config.zoneRadius);
  const feature = new Feature(geometry);
  const source = new VectorSource({
    projection: "EPSG:3857",
    features: [feature],
  });
  feature.setProperties({ type: "zone" });
  geometry.setCoordinates(centerLongitudeLatitude);

  const layer = new VectorLayer({
    source,
    style: [
      new Style({
        stroke: new Stroke({
          color: "blue",
          width: 3,
        }),
        // fill: new Fill({
        //   color: 'rgba(0, 0, 255, 0.1)',
        // }),
      }),
    ],
  });
  return { layer, source, geometry, feature };
};

export const getZoneRadiusInMap = () => {
  const newMapExt = transformExtent(getMapExt(), "EPSG:3857", "EPSG:4326");
  // const mapDiagDist = parseInt(getDistance([ newMapExt[0],newMapExt[1] ], [ newMapExt[2],newMapExt[3] ]))
  const mapWidth = Math.round(
    getDistance(getTopLeft(newMapExt), getTopRight(newMapExt))
  );
  const mapHeight = Math.round(
    getDistance(getTopLeft(newMapExt), getBottomLeft(newMapExt))
  );
  const radius = (mapWidth > mapHeight ? mapHeight : mapWidth) / 3;
  return radius;
};

const filterTracksInZone = () => {
  STORE.igcs.some((element, i) => {
    const { data, takeOff, trackId } = STORE.igcs[i];
    if (passFiltering({ takeOff, ...STORE.flights[trackId] })) {
      addTrack(data, i);
    }
  });
};
// const debouncedFilterTracksInZone = debounce(filterTracksInZone,100)

export const updateZone = () => {
  setURLParams({ key: PKEYS.gliderCat, value: store.state.dom.gliderEl });
  setURLParams({ key: PKEYS.duration, value: store.state.dom.durationEl });
  resetTime();
  clearTimePoints();
  removeAllThermals();
  resetPilots();
  STORE.tracksVectorSource.clear();
  STORE.countInZone = 0;
  filterTracksInZone();
};

export const addZoneLayer = () => {
  const { layer, source, geometry, feature } = buildZone();
  STORE.zone = geometry;
  STORE.zoneFeature = feature;
  STORE.map.addLayer(layer);

  STORE.zoneModifier = new Modify({
    hitDetection: layer,
    source: source,
    pixelTolerance: 60,
    style: new Style({
      image: new CircleStyle({
        radius: 10,
        fill: new Fill({
          color: "rgba(0,0,255,1)",
        }),
        // stroke: new Stroke({
        //   color: "rgba(0,0,255,1)",
        //   width: 9,
        // }),
      }),
    }),
  });

  const mapEl = document.getElementById("map");

  STORE.zoneModifier.on(["modifystart", "modifyend"], function (evt) {
    const { zone, sitesSource, withTakeOffs } = STORE;
    mapEl.style.cursor = evt.type === "modifystart" ? "grabbing" : "all-scroll";
    STORE.point = null;
    STORE.line = null;
    if (evt.type === "modifyend") {
      const center = zone.getCenter();
      const radius = zone.getRadius();
      setURLParams({ key: PKEYS.zoneRadius, value: parseInt(radius) });
      setURLParams({ key: PKEYS.zoneCenter, value: center });
      store.commit(MUTATIONS.clearSelectedPilots);
      store.commit(MUTATIONS.clearAnalyses);
      updateZone();
      //mapFitZone();

      if (withTakeOffs) {
        sitesSource.clear();
        getTakeOffMarkers();
      }
      gtagEvent("filter", { moveZone: true });
    }
  });

  const overlaySource = STORE.zoneModifier.getOverlay().getSource();
  overlaySource.on(["addfeature", "removefeature"], function (evt) {
    if (evt.type === "addfeature") {
      mapEl.style.cursor = "all-scroll";
      STORE.isZoneGrabber = true;
    } else {
      mapEl.style.cursor = "";
      STORE.isZoneGrabber = false;
    }
  });

  if (!store.state.dom.zoneEl) {
    hideZone();
  } else {
    STORE.map.addInteraction(STORE.zoneModifier);
  }
};

export const setZoneAroundTakeOff = ({ takeOff }) => {
  const { zone } = STORE;
  const center = fromLonLat(takeOff.lnglat);
  const radius = 10000;
  zone.setCenter(center);
  zone.setRadius(radius);
  setURLParams({ key: PKEYS.zoneRadius, value: radius });
  setURLParams({ key: PKEYS.zoneCenter, value: center });
};
