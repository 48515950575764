<template>
  <div class="footer">
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Infos </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            XC Finder permet de voir les derniers vols en
            <strong>parapente</strong> réalisés en France dans le cadre de la
            CFD (coupe fédérale de distance), comparer des traces sur une
            période donnée, trouver les itinéraires de cross les plus volés dans
            une zone géographique, découvrir de nouveaux sites et décollages...
          </p>
          <p>
            Les traces sont actualisées en continu. Les nouveaux vols
            apparaissent toutes les heures.
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header> FAQ </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="question">
            <div>Comment comparer plusieurs traces?</div>
          </div>
          <div class="answer">
            <ul>
              <li>
                Sélectionner/Déselectionner des traces dans la liste des pilotes
              </li>
              <li>Déplacer le curseur pour voir l'évolution des pilotes.</li>
            </ul>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Supporter le projet 🍺
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Les mises à jour, le traitement des données, l'hébergement
          occasionnent des frais. Voici un lien Paypal sécurisé pour soutenir le
          projet... Bons vols!
          <div class="donate">
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_top"
            >
              <input type="hidden" name="business" value="FCM7YWQUN2VSJ" />
              <input type="hidden" name="no_recurring" value="0" />
              <input
                type="hidden"
                name="item_name"
                value="Pour soutenir le projet XC Finder!"
              />
              <input type="hidden" name="currency_code" value="EUR" />
              <input
                type="image"
                src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Bouton Faites un don avec PayPal"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/fr_FR/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header> Crédits </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            <strong>Données de vols : </strong>
            <a href="https://parapente.ffvl.fr/cfd/" target="_blank"
              >CFD - FFVL</a
            >
          </p>
          <!-- <p>
            <strong>Décollages</strong><br />
            <a href="https://www.paraglidingearth.com" target="_blank"
              >paraglidingearth.com</a
            >
          </p> -->
          <p>
            <strong>XC finder : </strong>
            <a
              href="https://www.youtube.com/channel/UCpkuD4na5Imk7RcgBts-NQQ/about"
              target="_blank"
              >Sébastien Picot</a
            >
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- <v-expansion-panel>
        <v-expansion-panel-header> xxx </v-expansion-panel-header>
        <v-expansion-panel-content> xxx </v-expansion-panel-content>
      </v-expansion-panel> -->
    </v-expansion-panels>
    <!-- <div class="fbshare">
      <iframe
        src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fxcfinder.com&layout=button_count&size=small&appId=1242557486196271&width=97&height=20"
        width="97"
        height="20"
        style="border: none; overflow: hidden"
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div> -->
  </div>
</template>

<script>
import { EXT_LINKS } from "../config";
export default {
  name: "Footer",
  data: function () {
    return {
      links: [],
    };
  },
  created() {
    this.links = EXT_LINKS;
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin: 0 -20px;
}
.accordion {
  width: 100%;
  overflow: hidden;
}

.question {
  font-weight: bold;
  margin-bottom: 5px;
}
.answer {
  margin-top: 0px;
  padding: 10px;
  padding-left: 12px;
  margin-bottom: 10px;
  background: lightyellow;
}

.footer {
  p {
    margin-bottom: 10px;
  }
}

.links {
  list-style: disc;
  margin-left: 16px;
}

.donate {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin-top: 15px;
  .cheers {
    font-size: 50px;
  }
  * {
    border: none;
    background: none;
    transform: scale(0.9);
    transform-origin: left top;
    margin: 0;
    padding: 0;
  }
}
</style>
