<template>
  <div id="pilotsWrapper" class="whiteBox" v-if="isPilots">
    <div class="ctrl">
      <label for="sortPilotsBy" class="label">Trier par </label>
      <select id="sortPilotsBy" v-model="sortBy" @input="onSort">
        <option value="date">date</option>
        <option value="distance">distance (km)</option>
        <option value="points">points</option>
        <option value="altitudeMax">plaf max (m)</option>
        <option value="speed">vitesse (km/h)</option>
        <option value="flightType">type de vol</option>
        <option value="pilotLastName">pilote (nom)</option>
        <option value="gliderCat">aile (cat)</option>
        <option value="gliderName">aile (nom)</option>
        <option value="departement">departement</option>
        <option value="club">club</option>
        <option value="lat">latitude</option>
      </select>
    </div>
    <div id="pilots">
      <Pilot
        v-for="pilot in sortedPilots"
        :key="pilot.trackId"
        :trackId="pilot.trackId"
        :extra="sortBy"
      />
    </div>
  </div>
</template>
<script>
import { gtagEvent } from "../controllers/helpers";
import Pilot from "./Pilot.vue";
export default {
  components: { Pilot },
  name: "Pilots",
  data: function () {
    return {
      sortBy: "distance",
    };
  },
  computed: {
    isPilots() {
      return !!this.sortedPilots.length;
    },
    sortedPilots() {
      const pilots = this.$store.state.pilots;
      const isStr = ["flightType"];
      const isAsc = [
        "pilotLastName",
        "gliderName",
        "gliderBrand",
        "gliderCat",
        "departement",
        "club",
      ];
      return pilots.sort((a, b) => {
        const ax = a[this.sortBy];
        const bx = b[this.sortBy];
        if (isAsc.includes(this.sortBy)) return (ax > bx) - (ax < bx);
        return isStr.includes(this.sortBy) ? (ax < bx) - (ax > bx) : bx - ax;
      });
    },
  },
  methods: {
    onSort(e) {
      gtagEvent("sort", { by: e.target.value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";
#pilotsWrapper {
  position: relative;
  overflow: hidden;
  h3 {
    margin-bottom: 10px;
  }
  &:after {
    content: "";
    background-image: linear-gradient(rgba(#fff, 0), #fff);
    height: 70px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
  #pilots {
    max-height: 200px;
    overflow: scroll;

    @media (min-width: $screen-lg) {
      max-height: calc(60vh - 300px);
    }
    > div:last-child {
      margin-bottom: 40px;
    }
  }
}
</style>
