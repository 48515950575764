<template>
  <div>
    <div id="map" class="map" @mousemove="onMouseMove"></div>
    <div
      class="altitudeTooltip"
      :style="{ top: clientY, left: clientX }"
      v-if="info.altitude && !tracksEl"
    >
      {{ info.altitude }}m à {{ info.time }}
    </div>
  </div>
</template>
<script>
import { initMap } from "../controllers/map";
import { addMarkers, addSitesLayer } from "../controllers/markers";
import { addTracksLayer } from "../controllers/tracks";
import { addZoneLayer } from "../controllers/zone";
export default {
  name: "Map",
  mounted() {
    initMap();
    addZoneLayer();
    addTracksLayer();
    addSitesLayer();
  },
  data: function () {
    return {
      clientX: 1000,
      clientY: 1000,
    };
  },
  computed: {
    takeoffs() {
      return this.$store.state.data.takeoffs;
    },
    info() {
      return this.$store.state.info;
    },
    tracksEl() {
      return this.$store.state.dom.tracksEl;
    },
  },
  watch: {
    takeoffs(nv) {
      if (nv && !!nv.length) {
        addMarkers();
      }
    },
  },
  methods: {
    onMouseMove(event) {
      this.clientX = event.clientX + "px";
      this.clientY = event.clientY + "px";
      //console.log(event.clientX, event.clientY);
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/styles/variables";
@mixin true-vh-calc($value, $type: "height", $base: "100") {
  $true-vh: calc(var(--vh, 1vh) * #{$base} - (#{$value}));

  @if $type == "height" {
    height: $true-vh;
  }
  @if $type == "max-height" {
    max-height: $true-vh;
  }
  @if $type == "min-height" {
    min-height: $true-vh;
  }
  @if $type == "top" {
    top: $true-vh;
  }
}

.altitudeTooltip {
  position: absolute;
  transform: translate(10px, 0px);
  transform-origin: center left;
  z-index: 100;
  padding: 5px;
  background: #111;
  font-size: 11px;
  color: #fff;
  border-radius: 5px;
}
.map {
  width: 100%;
  @include true-vh-calc(100px);
  min-height: -webkit-fill-available;

  padding: 0;
  margin: 0;

  @media (min-width: $screen-md) {
    width: 100%;
    height: calc(100vh);
    padding: 0;
    margin: 0;
    position: fixed;
    top: 0;
  }
}
</style>
