<template>
  <button id="backButton" class="whiteBox" @click="onBackClick">
    ⮐ Retour
  </button>
</template>
<script>
import { gtagEvent } from "../controllers/helpers";
import { resetSelectedPilots } from "../controllers/pilots";
export default {
  name: "BackButton",
  methods: {
    onBackClick() {
      resetSelectedPilots();
      gtagEvent("analyse", { back: true });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables";
#backButton {
  display: flex;
  margin: 1px 0;
  border: none;
  height: 40px;
  font-weight: bold;
  position: fixed;
  top: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: blue;
  color: #fff;

  @media (min-width: $screen-md) {
    position: relative;
    top: 0px;
    right: auto;
    left: 0px;
    //box-shadow: none!important;
    margin-top: 0;
    margin-bottom: 15px;
  }
}
</style>
