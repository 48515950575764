import { Feature, Overlay } from "ol";
import Point from "ol/geom/Point";
import { Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { STORE } from "..";
import store, { MUTATIONS } from "../store";
import { isSelectedPilot, isSelectedPilots } from "./pilots";

export const timePointOverStyle = new Style({
  image: new CircleStyle({
    radius: 4,
    fill: new Fill({
      color: "white",
    }),
    stroke: new Stroke({
      color: "blue",
      width: 2,
    }),
  }),
  zIndex: 103,
});

export const styleTimePoint = ({ feature }) => {
  const color = feature.get("color");
  const zIndex = STORE.flights.length - feature.get("id");
  return new Style({
    image: new CircleStyle({
      radius: 4,
      fill: new Fill({
        color: "white",
      }),
      stroke: new Stroke({
        color: color,
        width: 2,
      }),
    }),
    zIndex: zIndex + 2,
  });
};

const addPilotOverlay = (feature) => {
  const pilotTooltip = document.createElement("div");
  pilotTooltip.classList.add("pilotTooltip");
  const pilotOverlay = new Overlay({
    element: pilotTooltip,
  });
  feature.set("pilotOverlay", pilotOverlay);
  feature.set("pilotTooltip", pilotTooltip);
  STORE.map.addOverlay(pilotOverlay);
};

export const removePilotOverlay = (trackId) => {
  const { map } = STORE;
  const { feature } = STORE.igcs[trackId];
  map.removeOverlay(feature.get("pilotOverlay"));
  feature.unset("pilotOverlay");
};

const getClosestDirAtM = ({ trackId, m }) => {
  const analyse = store.state.analyses.find(
    (analyse) => analyse.trackId === trackId
  );
  return (
    analyse &&
    analyse.entries.reduce(
      function (prev, cur) {
        return Math.abs(cur.time - m) < Math.abs(prev.time - m) ? cur : prev;
      },
      { time: 0 }
    )
  );
};

const getClosestAltitudeAtM = ({ altitudes, m }) => {
  const res = altitudes.reduce(
    (prev, cur) => {
      return Math.abs(cur[0] - m) < Math.abs(prev[0] - m) ? cur : prev;
    },
    ["0", 0]
  );
  return res[1];
};

const updatePilotOverlay = ({ feature, coordinate, m }) => {
  const pilotOverlay = feature.get("pilotOverlay");
  const pilotTooltip = feature.get("pilotTooltip");
  const altitudes = feature.get("altitudes");
  const { color } = STORE.flights[feature.id_];
  //const alt = getClosestAltitudeAtM({ feature, m });
  const alt =
    altitudes[Math.floor(m)] ||
    getClosestAltitudeAtM({ altitudes: Object.entries(altitudes), m });
  const altitude = alt ? '<span class="altitude">' + alt + "m</span>" : "";
  const { pilotInit } = STORE.flights[feature.id_];
  const analyse = getClosestDirAtM({ trackId: feature.id_, m });
  const name =
    '<span class="pilotNameColor" style="background:' +
    color +
    '">' +
    pilotInit +
    "</span>";

  //let direction = "";
  //let distance = "";
  let mode = "";
  if (analyse) {
    // for (let index = 0; index < Math.min(10, analyse.power); index++) {
    //   direction += "<span class='direction " + analyse.dir + "'></span>";
    // }
    //distance = "<div>" + Math.floor(analyse.distance) + "</div>";
    switch (analyse.mode) {
      case "transition":
        mode = '<i class="icon-right cold"></i>';
        break;
      case "prospection":
        mode = '<i class="icon-loop grey"></i>';
        break;
      case "cheminement":
        mode = '<i class="icon-right warm"></i>';
        break;
      case "thermique":
        mode = '<i class="icon-up warm"></i>';
        break;
      default:
        mode = "";
        break;
    }
  }

  pilotTooltip.innerHTML = name + altitude + mode;
  pilotOverlay.setPosition(coordinate);
};

export const addTimePoint = ({ feature, m }) => {
  if (isSelectedPilots() && !isSelectedPilot(feature.id_)) {
    return;
  }

  const { tracksVectorLayer } = STORE;
  const geometry = feature.getGeometry();
  const coordinate = geometry.getCoordinateAtM(m, true);
  let timePoint = feature.get("timePoint");
  const style = styleTimePoint({
    feature,
    isInFlight: false,
  });
  if (timePoint === undefined) {
    timePoint = new Feature(new Point(coordinate));
    feature.set("timePoint", timePoint);
    tracksVectorLayer.getSource().addFeature(timePoint);
    timePoint.setProperties({ type: "timePoint" });
  } else {
    timePoint.getGeometry().setCoordinates(coordinate);
  }
  timePoint.setStyle(style);

  if (isSelectedPilot(feature.id_)) {
    const pilotOverlay = feature.get("pilotOverlay");
    if (pilotOverlay === undefined) {
      addPilotOverlay(feature);
    }
    updatePilotOverlay({ feature, geometry, coordinate, m });
  }
};

export const removeTimePoint = (feature) => {
  const timePoint = feature.get("timePoint");
  if (timePoint) {
    STORE.tracksVectorLayer.getSource().removeFeature(timePoint);
  }
  feature.unset("timePoint");
};

export const setTimeFromTrack = (feature) => {
  const { time } = store.state;
  const geometry = feature.getGeometry();
  const start = Math.min(time.start, geometry.getFirstCoordinate()[2]);
  const stop = Math.max(time.stop, geometry.getLastCoordinate()[2]);
  const duration = stop - start;
  store.commit(MUTATIONS.setTime, { start, stop, duration });
};

export const resetTime = () => {
  store.commit(MUTATIONS.setTime, {
    start: Infinity,
    stop: -Infinity,
    duration: 0,
  });
};

export const clearTimePoints = () => {
  const { tracksVectorLayer } = STORE;
  tracksVectorLayer.getSource().clear();
  STORE.tracksVectorSource.forEachFeature(function (feature) {
    feature.unset("timePoint");
    removePilotOverlay(feature.id_);
  });
};
