<template>
  <div id="loader" :style="{ width }"></div>
</template>
<script>
export default {
  name: "ResLoader",
  props: [],
  computed: {
    width() {
      const { pilots, totalFlights } = this.$store.state;
      const perc = Math.round((pilots.length / totalFlights) * 100);
      return `${perc}%`;
    },
  },
};
</script>
<style scoped lang="scss">
#loader {
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  overflow: hidden;
  max-width: 100%;
  background: rgba($color: blue, $alpha: 0.1);
  //transition: width linear 0.1s;
}
</style>
