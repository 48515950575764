import dayjs from "dayjs";
import { STORE } from "..";
import { config } from "../config";
import store, { MUTATIONS } from "../store";

export const params = new URLSearchParams(location.search);

export const PKEYS = {
  zoneRadius: "zr",
  zoneCenter: "zc",
  mapZoom: "mz",
  mapCenter: "mc",
  date: "d",
  delta: "dd",
  gliderCat: "g",
  duration: "du",
};

export const setURLParams = ({ key, value }) => {
  params.set(key, value);
  const link = `${location.pathname}?${params.toString()}`;
  store.commit(MUTATIONS.setExportLink, link);
};

const getURLParams = () => {
  // for (const [ key, value ] of params) {
  //   console.log(`${key}: ${value}`)
  // }
  const pzr = params.get(PKEYS.zoneRadius);
  const pzc = params.get(PKEYS.zoneCenter);
  const pmz = params.get(PKEYS.mapZoom);
  const pmc = params.get(PKEYS.mapCenter);
  const d = params.get(PKEYS.date);
  const dd = params.get(PKEYS.delta);
  const g = params.get(PKEYS.gliderCat);
  const du = params.get(PKEYS.duration);

  return { pzr, pzc, pmz, pmc, d, dd, g, du };
};

export const initParams = () => {
  const { pzr, pzc, pmz, pmc, d, dd } = getURLParams();
  const zoneRadius = pzr && parseInt(pzr);
  const zoneCenter = pzc && pzc.split(",").map((v) => parseFloat(v));
  const mapZoom = pmz && parseFloat(pmz);
  const mapCenter = pmc && pmc.split(",").map((v) => parseFloat(v));
  const date = d || dayjs.unix(config.date).format("YYYY-MM-DD");
  const delta = dd || localStorage.delta || config.delta;

  zoneRadius && STORE.zone.setRadius(zoneRadius);
  zoneCenter && STORE.zone.setCenter(zoneCenter);
  mapZoom && STORE.map.getView().setZoom(mapZoom);
  mapCenter && STORE.map.getView().setCenter(mapCenter);

  store.commit(MUTATIONS.setDomValues, {
    dateEl: date,
    deltaEl: delta,
    departementEl: localStorage.departement || "",
    durationEl: localStorage.duration || config.duration,
    distanceMinEl: parseInt(localStorage.distanceMin || config.distanceMin),
    distanceMaxEl: parseInt(localStorage.distanceMax || config.distanceMax),
    gliderEl:
      (localStorage.gliderCat && localStorage.gliderCat.split(",")) ||
      config.gliderCat,
    flightTypeEl: config.flightType,
  });
};

export const saveBackValues = () => {
  const { zone, map } = STORE;
  STORE.backValues = {
    mz: map.getView().getZoom(),
    mc: map.getView().getCenter(),
    zc: zone.getCenter(),
    zr: zone.getRadius(),
  };
};

export const setBackValues = () => {
  const {
    zone,
    map,
    backValues: { mz, mc, zc, zr },
  } = STORE;
  map.getView().setZoom(mz);
  map.getView().setCenter(mc);
  zone.setRadius(zr);
  zone.setCenter(zc);
  map.render();
};
