import dayjs from "dayjs";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import { getVectorContext } from "ol/render";
import { Fill, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Stroke from "ol/style/Stroke";
import { STORE } from "..";
import store, { MUTATIONS } from "../store";
import { getDistInPixels } from "./helpers";
import { isSelectedPilot, isSelectedPilots } from "./pilots";

const stroke = new Stroke({
  color: "rgba(0,0,255,0.5)",
  width: 1,
});

const style = new Style({
  stroke: stroke,
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: "rgba(0,0,255,0.5)",
    }),
    stroke: stroke,
  }),
});

export const drawPointLine = ({ event }) => {
  const { point, line } = STORE;
  const vectorContext = getVectorContext(event);
  vectorContext.setStyle(style);
  if (point !== null) {
    vectorContext.drawGeometry(point);
  }
  if (line !== null) {
    vectorContext.drawGeometry(line);
  }
};

export const displaySnap = ({ coordinate }) => {
  if (store.state.dom.tracksEl) return;
  const { tracksVectorSource, point, flights, map } = STORE;
  const closestFeature = tracksVectorSource.getClosestFeatureToCoordinate(
    coordinate,
    (f) => {
      const isFiltered =
        !isSelectedPilots() || (isSelectedPilots() && isSelectedPilot(f.id_));
      return isFiltered;
    }
  );
  if (closestFeature === null) {
    STORE.point = null;
    STORE.line = null;
  } else {
    const geometry = closestFeature.getGeometry();
    const closestPoint = geometry.getClosestPoint(coordinate);
    const altitudes = closestFeature.get("altitudes");
    const altitude = altitudes[Math.floor(closestPoint[2])];
    const coordinates = [coordinate, [closestPoint[0], closestPoint[1]]];
    const lineDist = getDistInPixels(coordinates[0], coordinates[1]);

    if (lineDist > 100) {
      STORE.point = null;
      STORE.line = null;
      store.commit(MUTATIONS.setInfo, {
        type: null,
      });
    } else {
      if (point === null) {
        STORE.point = new Point(closestPoint);
        STORE.line = new LineString(coordinates);
      } else {
        STORE.point.setCoordinates(closestPoint);
        STORE.line.setCoordinates(coordinates);
      }
      store.commit(MUTATIONS.setInfo, {
        type: "track",
        time: dayjs.unix(closestPoint[2]).format("HH[h]mm"),
        altitude,
        ...flights[closestFeature.id_],
      });
    }
  }

  map.render();
};
