<template>
  <select id="sites" :value="sitesEl" @input="onSiteChange($event)">
    <option value="46.89,1.90,800">Toute la France</option>
    <optgroup label="Alpes du Nord">
      <option value="45.8532,6.22299,40">Annecy</option>
      <option value="45.9356,6.855,40">Chamonix</option>
      <option value="45.3069,5.88806,40">Saint Hilaire du Touvet</option>
    </optgroup>
    <optgroup label="Alpes du Sud">
      <option value="43.8136,6.80849,40">Col de Bleyne</option>
      <option value="44.9124,5.5847,40">Le Serpaton</option>
      <option value="43.9786,6.4799,40">Saint André les Alpes</option>
    </optgroup>
    <optgroup label="Est">
      <option value="47.9256,7.011,40">Markstein</option>
    </optgroup>
    <optgroup label="Sud">
      <option value="44.11,3.1008,40">Millau</option>
    </optgroup>
    <optgroup label="Pyrennées">
      <option value="42.97,0.89,20">Arbas</option>
      <option value="42.80,0.37,20">Val Louron</option>
    </optgroup>
  </select>
</template>

<script>
import { fromLonLat } from "ol/proj";
import { STORE } from "..";
import { gtagEvent } from "../controllers/helpers";
import { resetSelectedPilots } from "../controllers/pilots";
import { MUTATIONS } from "../store";
import { mapFitZone } from "../controllers/map";
export default {
  name: "Filters",
  computed: {
    dom() {
      return this.$store.state.dom;
    },
    sitesEl() {
      return this.dom.sitesEl;
    },
  },

  methods: {
    onSiteChange(e) {
      const { value } = e.target;
      this.$store.commit(MUTATIONS.setDomValues, {
        sitesEl: value,
      });
      const values = value.split(",");
      const lat = values[0];
      const lng = values[1];
      const rayon = values[2];
      STORE.zone.setRadius(rayon * 1000);
      STORE.zone.setCenter(fromLonLat([lng, lat]));
      STORE.map.getView().setCenter(fromLonLat([lng, lat]));
      resetSelectedPilots({ shouldUpateView: false, shouldUpdateZone: true });
      mapFitZone();
      window.scrollTo(0, 0);
      gtagEvent("quick access", { site: value });
    },
  },
};
</script>
<style lang="scss" scoped>
select {
  font-size: 14px;
}
</style>
