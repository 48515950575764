import axios from "axios";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import { STORE } from "..";
import store, { MUTATIONS } from "../store";

export const markerOverStyle = new Style({
  image: new CircleStyle({
    radius: 8,
    fill: new Fill({ color: "blue" }),
  }),
});

export const buildSitesMarkers = () => {
  const source = new VectorSource();
  const layer = new VectorLayer({
    source,
    style: new Style({
      image: new CircleStyle({
        radius: 4,
        fill: new Fill({ color: "blue" }),
      }),
    }),
  });

  return { source, layer };
};

export const addMarkers = () => {
  store.state.data.takeoffs.some((takeoff) => {
    const { lng, lat } = takeoff;
    const marker = new Feature(new Point(fromLonLat([lng, lat])));
    marker.setProperties({ type: "site", ...takeoff });
    STORE.sitesSource.addFeature(marker);
  });
};

export const getTakeOffMarkers = async () => {
  const { zone } = STORE;
  const centerLonLat = toLonLat(zone.getCenter());
  const limit = 50;
  const radiusInKm = zone.getRadius() / 1000;

  store.commit(MUTATIONS.setLoadingTakeoffs, true);
  try {
    const url =
      process.env.VUE_APP_API_URL +
      "/sites?lat=" +
      centerLonLat[1] +
      "&lng=" +
      centerLonLat[0] +
      "&distance=" +
      radiusInKm +
      "&limit=" +
      limit;

    const {
      data: {
        search: { takeoff: takeoffs },
      },
    } = await axios.get(url);

    if (takeoffs.length) {
      store.commit(MUTATIONS.setTakeoffs, takeoffs);
    }
  } catch (error) {
    console.log({ error });
  }
  store.commit(MUTATIONS.setLoadingTakeoffs, false);
};

//export const debouncedGetTakeOffMarkers = debounce(getTakeOffMarkers, 500);

export const setMarkerInfo = (props) => {
  const { name, takeoff_altitude, orientations, lat, lng } = props;
  store.commit(MUTATIONS.setInfo, {
    type: "site",
    name,
    takeoff_altitude,
    orientations,
    lat,
    lng,
  });
};

export const addSitesLayer = () => {
  const { layer, source } = buildSitesMarkers();
  STORE.map.addLayer(layer);
  STORE.sitesSource = source;
  STORE.sitesLayer = layer;
};
