<template>
  <div class="filters">
    <div class="ctrl switchbox">
      <label for="zone" class="label">Zone de décollage</label>
      <label class="switch">
        <input id="zone" type="checkbox" v-model="zoneEl" />
        <span class="slider round"></span>
      </label>
      <v-tooltip top max-width="200">
        <template v-slot:activator="{ on, attrs }">
          <span class="tooltip" v-bind="attrs" v-on="on">?</span>
        </template>
        <span>
          Déplacer la zone par le centre <br />
          Modifier le rayon par le bord
        </span>
      </v-tooltip>
    </div>
    <div class="ctrl">
      <label for="flightType" class="label">Type de vol</label>
      <select
        id="flightType"
        :value="dom.flightTypeEl"
        @input="onFlightTypeChange($event)"
      >
        <option value="">Tous</option>
        <option
          v-for="flightType in flightTypes"
          :key="flightType"
          :value="flightType"
        >
          {{ flightType }}
        </option>
      </select>
    </div>
    <div class="ctrl">
      <label for="duration" class="label">Durée de vol mini</label>
      <select
        id="duration"
        :value="dom.durationEl"
        @input="onDurationChange($event)"
      >
        <option v-for="dur in 12" :key="dur" :value="dur">{{ dur }}h</option>
      </select>
    </div>
  </div>
</template>

<script>
import { STORE } from "..";
import { gtagEvent } from "../controllers/helpers";
import { getZoneRadiusInMap, hideZone, updateZone } from "../controllers/zone";
import { resetSelectedPilots, updatePilotsInMap } from "../controllers/pilots";
import { MUTATIONS } from "../store";

export default {
  name: "Filters",
  computed: {
    dom() {
      return this.$store.state.dom;
    },
    flightTypes() {
      return this.$store.getters.flightTypes;
    },

    zoneEl: {
      get() {
        return this.$store.state.dom.zoneEl;
      },
      set(checked) {
        this.$store.commit(MUTATIONS.setDomValues, { zoneEl: checked });
        gtagEvent("filter", { zone: checked ? "check" : "uncheck" });
      },
    },
  },
  watch: {
    "$store.state.dom.zoneEl": function () {
      const checked = this.$store.state.dom.zoneEl;

      if (checked) {
        STORE.zone.setCenter(STORE.map.getView().getCenter());
        STORE.zone.setRadius(getZoneRadiusInMap());
        resetSelectedPilots({ shouldUpateView: false, shouldUpdateZone: true });
      } else {
        hideZone();
        updateZone();
        updatePilotsInMap();
      }
      window.scrollTo(0, 0);
    },
  },
  methods: {
    onDurationChange(e) {
      this.$store.commit(MUTATIONS.setDomValues, {
        durationEl: parseInt(e.target.value),
      });
      localStorage.duration = e.target.value;
      updateZone();
      gtagEvent("filter", { duration: e.target.value });
    },
    onFlightTypeChange(e) {
      this.$store.commit(MUTATIONS.setDomValues, {
        flightTypeEl: e.target.value,
      });
      updateZone();
      gtagEvent("filter", { flightType: e.target.value });
    },
  },
};
</script>
<style lang="scss" scoped>
.ctrl {
  display: flex;
  align-items: center;
}
.label {
  min-width: 130px;
  display: block;
}
</style>
