import { intersects } from "ol/extent";
import { STORE } from "..";
import store, { MUTATIONS } from "../store";
import { addThermals, analyseTrack, removeAllThermals } from "./analyse";
import { gtagEvent, passFiltering, scrollMobile } from "./helpers";
import { getMapExt, mapFitTracks } from "./map";
import {
  removePilotOverlay,
  resetTime,
  setTimeFromTrack,
  styleTimePoint,
  timePointOverStyle,
} from "./replay";
import {
  hideTrack,
  showTrack,
  trackOverStyle,
  trackUnselectedStyle,
} from "./tracks";
import { saveBackValues, setBackValues } from "./urlParams";
import { setZoneAroundTakeOff, showZone, updateZone } from "./zone";

export const resetPilots = () => {
  store.commit(MUTATIONS.clearPilots);
};

export const togglePilot = (trackId) => {
  if (isSelectedPilot(trackId)) {
    unselectPilot({ trackId });
  } else {
    selectPilot({ trackId });
  }
};

export const selectPilot = ({ trackId }) => {
  const { feature, takeOff } = STORE.igcs[trackId];
  //window.scrollTo(0, 0);

  store.commit(MUTATIONS.selectPilot, trackId);

  if (store.state.selectedPilots.length === 1) {
    saveBackValues();
    store.commit(MUTATIONS.setDomValues, { zoneEl: false, thermalsEl: false });
    setZoneAroundTakeOff({ takeOff });
    updateZone();
    //updatePilotsInMap();
    resetTime();
  } else {
    showTrack(trackId);
  }

  setTimeFromTrack(feature);
  mapFitTracks(store.state.selectedPilots);
  gtagEvent("analyse", { selectPilot: feature.get("pilot") });

  setTimeout(() => {
    const analyse = analyseTrack(feature);
    addThermals({ trackId, minAltGain: 100, minAveragePower: 2 });
    document.querySelector(".tracksVectorLayer").style.display = "block";
    store.commit(MUTATIONS.addAnalyse, analyse);
    scrollMobile(document.getElementById("replay"));
  }, 0);
};

export const unselectPilot = ({ trackId }) => {
  removeAllThermals();
  hideTrack(trackId);
  removePilotOverlay(trackId);

  store.commit(MUTATIONS.unselectPilot, trackId);
  store.commit(MUTATIONS.removeAnalyse, trackId);

  if (!isSelectedPilots()) {
    updateZone();
    updatePilotsInMap();
    if (store.state.dom.zoneEl) {
      showZone();
    }
  }
  gtagEvent("analyse", { unselectPilot: trackId });
};

export const resetSelectedPilots = ({
  shouldUpdateZone = true,
  shouldUpateView = true,
} = {}) => {
  store.state.selectedPilots.some((trackId) => {
    removePilotOverlay(trackId);
  });
  store.commit(MUTATIONS.clearSelectedPilots);
  store.commit(MUTATIONS.clearAnalyses);
  store.state.dom.zoneEl && showZone();
  shouldUpateView && setBackValues();
  shouldUpdateZone && updateZone();
};

export const addPilot = ({ trackId }) => {
  const { flights } = STORE;
  const {
    takeOff: { lnglat },
  } = STORE.igcs[trackId];

  store.commit(MUTATIONS.addPilot, {
    trackId,
    ...flights[trackId],
    lat: [lnglat[1]],
  });
};

export const isSelectedPilots = () => !!store.state.selectedPilots.length;
export const isSelectedPilot = (i) => store.state.selectedPilots.includes(i);

export const updatePilotsInMap = () => {
  resetPilots();
  STORE.igcs.some((igc, index) => {
    const { feature, takeOff, trackId } = igc;
    if (feature) {
      const mapExt = getMapExt();
      const isExtInMap = intersects(mapExt, feature.getGeometry().getExtent());
      STORE.igcs[index].isInMap = isExtInMap;
    }
    if (
      STORE.igcs[index].isInMap &&
      passFiltering({ takeOff, ...STORE.flights[trackId] })
    ) {
      addPilot({ trackId: index });
    }
  });
};

export const onPilotOver = (trackId) => {
  const { feature } = STORE.igcs[trackId];

  store.commit(MUTATIONS.setInfo, {
    type: "track",
    ...STORE.flights[trackId],
  });

  feature.setStyle(trackOverStyle);
  const timePoint = feature.get("timePoint");
  if (timePoint) {
    timePoint.setStyle(timePointOverStyle);
  }
};

export const onPilotOut = (trackId) => {
  const { feature } = STORE.igcs[trackId];
  const timePoint = feature.get("timePoint");
  const isSelected = isSelectedPilot(trackId);

  store.commit(MUTATIONS.setInfo, {
    type: null,
  });

  if (isSelectedPilots() && !isSelected) {
    feature.setStyle(trackUnselectedStyle);
  } else {
    feature.setStyle(null);
  }
  if (timePoint) {
    timePoint.setStyle(styleTimePoint({ feature, isInFlight: false }));
  }
};
