<template>
  <div class="wrapper">
    <h3>{{ title }}</h3>
    <p v-if="msg">{{ msg }}</p>
    <div class="buttons">
      <button @click="btnClicked(false)">Non</button>
      <button @click="btnClicked(true)">Oui</button>
    </div>
  </div>
</template>
<script>
import { scrollMobile } from "../controllers/helpers";
export default {
  name: "Dialog",
  data: () => ({
    show: true,
  }),
  props: ["title", "msg", "retval"],
  created() {
    this.show = true;
  },
  methods: {
    btnClicked: function (res) {
      this.retval = res;
      this.show = false;
    },
  },
  mounted() {
    setTimeout(() => {
      scrollMobile(document.getElementById("result"));
    }, 0);
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 15px;
  background: #fff;
  border-top: 1px solid #ddd;

  h3 {
    margin-bottom: 10px;
  }
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;

  button {
    display: block;
    height: 40px;
    flex: 1;
    margin: 0 5px;
  }
}
</style>
