import axios from "axios";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
//import { getDistance } from "ol/sphere";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { STORE } from "..";
import { config } from "../config";
import store, { MUTATIONS } from "../store";
import { removeAllThermals } from "./analyse";
import dialog from "./dialog";
import { gtagEvent, passFiltering, scrollMobile } from "./helpers";
import { mapFitTracks } from "./map";
import {
  addPilot,
  isSelectedPilot,
  isSelectedPilots,
  resetPilots,
  resetSelectedPilots,
} from "./pilots";
import {
  addTimePoint,
  clearTimePoints,
  removeTimePoint,
  resetTime,
  setTimeFromTrack,
} from "./replay";
import { drawPointLine } from "./snap";

export const styleCache = {};

export const trackOverStyle = [
  new Style({
    stroke: new Stroke({
      color: "white",
      width: 9,
    }),
    zIndex: 1000,
  }),
  new Style({
    stroke: new Stroke({
      color: "blue",
      width: 5,
    }),
    zIndex: 1001,
  }),
];

export const trackUnselectedStyle = new Style({
  stroke: new Stroke({
    color: "rgba(0,0,255,0)",
    width: 1,
  }),
});

const styleTrack = function (feature) {
  const color = feature.get("color");
  let style = styleCache[color];
  const zIndex = STORE.flights.length - feature.get("id");
  if (!style) {
    style = [
      new Style({
        stroke: new Stroke({
          color: color,
          width: config.trackWidth,
        }),
        zIndex: zIndex + 1,
      }),
      new Style({
        stroke: new Stroke({
          color: "#222",
          width: config.trackWidth + 2,
        }),
        zIndex,
      }),
    ];
    styleCache[color] = style;
  }
  return style;
};

export const buildTracks = () => {
  const source = new VectorSource();
  const layer = new VectorLayer({
    source,
    zIndex: 10,
    style: styleTrack,
    className: "tracksVectorLayer",
    // renderMode: "image",
  });
  return { source, layer };
};

export const resetTracks = () => {
  for (let index = 0; index < STORE.loadingIgcs.length; index++) {
    STORE.loadingIgcs[index].cancel(index);
  }
  if (STORE.resolveDialog) {
    STORE.resolveDialog({
      shouldLoadMore: false,
      dialogEl: document.getElementById("loadMoreDialog"),
    });
  }
  resetTime();
  clearTimePoints();
  removeAllThermals();
  resetSelectedPilots({ shouldUpdateZone: false, shouldUpateView: false });
  resetPilots();
  STORE.countInZone = undefined;
  STORE.igcs = [];
  STORE.loadingIgcs = [];
  STORE.tracksVectorSource.clear();
  store.commit(MUTATIONS.setTotalFlights, 0);
};

export const showTrack = (trackId) => {
  const { feature } = STORE.igcs[trackId];
  feature.setStyle(null);
  addTimePoint({ feature, m: 0 });
};
export const hideTrack = (trackId) => {
  const { feature } = STORE.igcs[trackId];
  feature.setStyle(trackUnselectedStyle);
  removeTimePoint(feature);
};

const onTracksLayerPostRender = (event) => {
  drawPointLine({ event });
};

export const addTracksLayer = () => {
  const { layer, source } = buildTracks();
  STORE.tracksVectorSource = source;
  STORE.tracksVectorLayer = layer;
  STORE.map.addLayer(STORE.tracksVectorLayer);
  STORE.tracksVectorLayer.on("postrender", onTracksLayerPostRender);
  //STORE.tracksVectorSource.on("addfeature", onFeatureTrackAdded);
};

export const addTrack = (data, i) => {
  const features = STORE.igcFormat.readFeatures(data, {
    featureProjection: "EPSG:3857",
  });
  const { pilotFullName, color } = STORE.flights[i];

  if (!features[0]) return;

  features[0].setId(i);
  features[0].setProperties({
    id: i,
    type: "track",
    pilot: pilotFullName,
    color,
  });
  STORE.countInZone = STORE.countInZone + 1;

  if (isSelectedPilots() && !isSelectedPilot(i)) {
    // console.log('addTrack unselected', i)
    features[0].setStyle(trackUnselectedStyle);
  } else {
    features[0].setStyle(null);
    addTimePoint({ feature: features[0], m: 0 });
  }

  STORE.tracksVectorSource.addFeatures(features);
  STORE.igcs[i] = {
    ...STORE.igcs[i],
    feature: features[0],
    trackId: i,
  };

  setTimeFromTrack(features[0]);
  addPilot({ trackId: i });
};

// Parses a latitude and longitude in the form:
// DDMMmmmNDDDMMmmmE
// where M = minutes and m = decimal places of minutes.
const parseLongLat = (latLongString) => {
  let latitude =
    parseFloat(latLongString.substring(0, 2)) +
    parseFloat(latLongString.substring(2, 7)) / 60000.0;
  if (latLongString.charAt(7) === "S") {
    latitude = -latitude;
  }
  let longitude =
    parseFloat(latLongString.substring(8, 11)) +
    parseFloat(latLongString.substring(11, 16)) / 60000.0;
  if (latLongString.charAt(16) === "W") {
    longitude = -longitude;
  }
  return [longitude, latitude];
};

const getTakeOff = (igc) => {
  const lines = igc.split("\n");
  const points = lines.filter((l) => l[0] === "B");
  return {
    lnglat: parseLongLat(points[0].substring(7, 24)),
  };
};

const onIgcLoaded = (i, data) => {
  const takeOff = getTakeOff(data);
  STORE.igcs[i] = {
    data,
    takeOff,
    ...STORE.flights[i],
  };
  if (passFiltering({ takeOff, ...STORE.flights[i] })) {
    addTrack(data, i);
  }

  store.commit(MUTATIONS.setLoadedIgcs, STORE.igcs.length);
};

function chunkArray(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export const getIgcs = async () => {
  scrollMobile(document.getElementById("result"));
  store.commit(MUTATIONS.setIsMoreSearch, true);
  store.commit(MUTATIONS.setTotalFlights, STORE.flights.length);

  if (STORE.flights === "error" || !STORE.flights.length) {
    return;
  }

  store.commit(MUTATIONS.setLoadingIgcs, true);
  STORE.countInZone = 0;

  const igcUrls = STORE.flights.map(({ igcUrl }, index) => ({ igcUrl, index }));
  const chunks = chunkArray(igcUrls, config.igcChunkSize);

  //console.log("##CAP##", STORE.loadingIgcs);
  for (let i = 0; i < chunks.length; ++i) {
    //console.log("##CAP##", "load chunk", i);
    await Promise.all(
      chunks[i].map(({ igcUrl, index }) => {
        const cancelTokenSource = axios.CancelToken.source();
        STORE.loadingIgcs.push(cancelTokenSource);
        return axios
          .get(igcUrl, {
            cancelToken: cancelTokenSource.token,
          })
          .then((res) => {
            onIgcLoaded(index, res.data);
          })
          .catch(function (idx) {
            if (axios.isCancel(idx)) {
              console.log("Request canceled", STORE.flights[idx].flightUrl);
            }
          });
      })
    );

    mapFitTracks(store.state.pilots.map(({ trackId }) => trackId));

    if (i <= chunks.length - 2) {
      const shouldLoadMore = await dialog({
        title: `Charger plus de traces ?`,
        msg: "",
      });

      if (!shouldLoadMore) {
        gtagEvent("loadmore", { by: "no" });
        break;
      } else {
        gtagEvent("loadmore", { by: "yes" });
      }
    }
  }
  store.commit(MUTATIONS.setLoadingIgcs, false);
};
