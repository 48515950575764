<template>
  <div>
    <v-btn
      @click.stop="drawer = !drawer"
      rounded
      fab
      elevation="2"
      class="burger-btn"
      >𑁔</v-btn
    >
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      width="340"
      hide-overlay
    >
      <div class="drawer-content">
        <ControlBox
          title="Rechercher des vols"
          :isOpenAtStart="true"
          classes="mt0"
          :isTitleImg="true"
        >
          <Search />
        </ControlBox>
        <ControlBox
          title="Filtrer"
          :isOpenAtStart="true"
          v-if="!!$store.state.data.flights.length"
        >
          <Filters />
        </ControlBox>
        <ControlBox title="Affichage" :isOpenAtStart="true">
          <!-- <Thermals /> -->
          Aller à <QuickAccess class="quickaccess" />
        </ControlBox>
        <!-- <ControlBox title="Options" :isOpenAtStart="true">
          <Options />
        </ControlBox> -->
        <Footer />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Footer from "./Footer.vue";
import Filters from "./Filters.vue";
import Search from "./Search.vue";
import ControlBox from "./ControlBox.vue";
import QuickAccess from "./QuickAccess.vue";
// import Thermals from "./Thermals.vue";

export default {
  components: { Search, Filters, Footer, ControlBox, QuickAccess },
  name: "Control",
  data: () => ({ isMobile: false, drawer: false }),
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    // if (this.isMobile) {
    //   this.drawer = false;
    // }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 760;
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/styles/variables";
.burger-btn.v-btn.theme--light {
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 25px;
  background-color: blue;
  color: #fff;
  border: none;
}
.drawer-content {
  padding: 20px;
  padding-bottom: 100px;
}
@media (min-width: $screen-md) {
  .burger-btn.v-btn.theme--light {
    top: 20px;
    left: 20px;
  }
}
</style>
