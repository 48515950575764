<template>
  <div id="app" data-app>
    <Map />
    <Analyse />
    <Control />
  </div>
</template>

<script>
import "ol/ol.css";
import Map from "./components/Map.vue";
import Analyse from "./components/Analyse.vue";
import Control from "./components/Control.vue";
import { initParams } from "./controllers/urlParams";
import { search } from "./controllers/api";

export default {
  name: "App",
  components: { Control, Analyse, Map },
  mounted() {
    initParams();
    search({ shouldReset: false });
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base.scss";
</style>
