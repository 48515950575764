<template>
  <div>
    <div id="resultatWrapper">
      <ResLoader v-if="isLoadingIgcs" />
      <div id="resultat">{{ resTxt }}</div>
    </div>
    <div id="loadMoreDialog"></div>
  </div>
</template>

<script>
import { STORE } from "..";
import { getFromTo } from "../controllers/helpers";
import ResLoader from "./ResLoader.vue";

export default {
  components: { ResLoader },
  name: "Result",
  computed: {
    isLoadingIgcs() {
      return this.$store.state.isLoadingIgcs;
    },
    resTxt() {
      const { isLoadingFlights, isLoadingIgcs, pilots, totalFlights } =
        this.$store.state;

      const { fromRead, toRead } = getFromTo();

      if (!isLoadingFlights && STORE.flights === "error") {
        return "Une erreur est survenue";
      }
      if (!isLoadingFlights && !isLoadingIgcs && !totalFlights) {
        return "Aucun vol pour cette recherche";
      }

      if (isLoadingFlights) {
        return "...";
      }

      // if (pilots.length === totalFlights) {
      //   return `${totalFlights} vols du ${fromRead} au ${toRead}`;
      // } else {
      return `${pilots.length}/${totalFlights} vols du ${fromRead} au ${toRead}`;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";
#resultatWrapper {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}
</style>
