<template>
  <div class="root">
    <div :class="classes" class="head">
      <h2 class="title">
        <!-- <img
          src="../assets/parapente.png"
          height="20"
          alt="parapente"
          v-if="isTitleImg"
        /> -->
        {{ title }}
      </h2>
      <!-- <button class="toggleBtn" @click="toggle">{{ btnTxt }}</button> -->
    </div>
    <div class="detail" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "ControlBox",
  props: ["title", "isOpenAtStart", "classes", "isTitleImg"],
  data() {
    return {
      isOpen: this.isOpenAtStart,
    };
  },
  computed: {
    btnTxt() {
      return this.isOpen ? "-" : "+";
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
<style scoped lang="scss">
.root {
  position: relative;
  margin-bottom: 30px;
}
.head {
  border-bottom: 1px dotted #ccc;
  color: #000;
  padding: 5px 0;
  //border-radius: 10px 0 10px 0;

  h2 {
    margin: 0;
    display: inline;
  }
}
.toggleBtn {
  $size: 18px;
  background: none;
  border: 1px solid #ddd;
  color: #aaa;
  font-size: 15px;
  //color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  border-radius: 2px;
  &:hover {
    background: #eee;
  }
}
.detail {
  margin-top: 5px;
  padding-top: 15px;
  //border-top: 1px dotted #ccc;
}
</style>
