import dayjs from "dayjs";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import { toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { getDistance } from "ol/sphere";
import { Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import { STORE } from "..";
// import chroma from "chroma-js";
// import store from "../store";

const getDistanceX = (coord1, coord2) => {
  const LonLat1 = toLonLat(coord1);
  const LonLat2 = toLonLat(coord2);
  const dist = getDistance(LonLat1, LonLat2);
  return Math.round(dist);
};

export const getTrackVariations = (feature) => {
  // Thermique  : 	0.5m/s  	30m/60s
  // Vz:			      35km/h 	583m/60s
  const variations = [];
  const altitudes = feature.get("altitudes");
  const geometry = feature.getGeometry();
  const alts = Object.entries(altitudes);
  let prevTime = parseInt(alts[0][0]);
  let prevAlt = alts[0][1];
  let prevCoordinate = geometry.getCoordinateAtM(prevTime);
  const altStep = 30; //m

  for (let index = 0; index < alts.length; index += 3) {
    const time = parseInt(alts[index][0]);

    const timeFmt = dayjs.unix(time).format("HH[h]mm");
    if (time > prevTime + 60) {
      const alt = alts[index][1];
      const isUp = alt > prevAlt;
      const deltaAlt = alt - prevAlt;
      const dir = isUp ? "up" : "down";
      const coordinate = geometry.getCoordinateAtM(time);
      const distance = getDistanceX(prevCoordinate, coordinate);
      const power = parseFloat(Math.abs(deltaAlt / altStep / 2).toFixed(2)); //m/s;
      let mode = "";

      // si j'avance
      if (distance > 400) {
        if (isUp) {
          // si mon altitude varie peu
          mode = "cheminement"; // ou transition en palier
        } else {
          // si mon altitude varie de + de 30m
          mode = "transition";
        }
      } else {
        // si j'avance peu
        // si mon altitude varie peu
        if (deltaAlt < altStep) {
          mode = "prospection";
        } else {
          mode = "thermique";
        }
      }

      variations.push({
        time,
        timeFmt,
        alt,
        dir,
        deltaAlt,
        power,
        distance, //meters
        mode,
        coordinate,
      });

      prevAlt = alt;
      prevTime = time;
      prevCoordinate = coordinate;
    }
  }

  return variations;
};

export const analyseTrack = (feature) => {
  const trackId = feature.id_;
  const pilot = feature.get("pilot");
  const altitudes = feature.get("altitudes");
  const entries = getTrackVariations(feature);
  const maxAlt = Object.values(altitudes).sort((a, b) => b - a)[0];

  const modes = {
    cheminement: entries.filter(({ mode }) => mode === "cheminement").length,
    transition: entries.filter(({ mode }) => mode === "transition").length,
    prospection: entries.filter(({ mode }) => mode === "prospection").length,
    thermique: entries.filter(({ mode }) => mode === "thermique").length,
  };

  return {
    trackId,
    maxAlt,
    pilot,
    modes,
    cheminement: perc(modes.cheminement / entries.length),
    transition: perc(modes.transition / entries.length),
    prospection: perc(modes.prospection / entries.length),
    thermique: perc(modes.thermique / entries.length),
    entries,
  };
};

const perc = (number) => {
  return Math.round(number * 100);
};

export const getThermals = ({ trackVariations, analyse }) => {
  const variations = trackVariations || analyse.entries;
  const thermals = [];
  let prevMode = "";
  let altGain = 0;
  let powers = [];

  variations.some(({ mode, power, deltaAlt, coordinate }) => {
    if (mode === "thermique") {
      if (prevMode !== "thermique") {
        //enter the thermal
        altGain = deltaAlt;
        thermals.push({ coordinate });
      } else {
        //in the thermal
        altGain = altGain + deltaAlt;
      }
      powers.push(power);
    } else if (prevMode === "thermique") {
      //leave the thermal
      const averagePower = parseFloat(
        (powers.reduce((a, b) => a + b) / powers.length).toFixed(2)
      );
      thermals[thermals.length - 1] = {
        ...thermals[thermals.length - 1],
        altGain,
        averagePower,
        maxPower: Math.max(...powers),
      };
      altGain = 0;
      powers = [];
    }
    prevMode = mode;
  });

  return thermals;
};

const thermalPoint = ({ altGain }) => {
  //const opacity = maxPower / 6;
  const radius = Math.max(2, Math.min(10, altGain / 70));

  //const color = chroma("#fff").darken(averagePower);
  // const color = chroma("#ff3352")
  //   .brighten(2)
  //   .darken(averagePower / 2)
  //   .hex();
  // const color = chroma.scale(["blue", "red"]).colors(10)[
  //   Math.round(Math.min(9, maxPower)) + 1
  // ];
  // console.log(
  //   "##CAP##",
  //   { averagePower, opacity, color },
  //   chroma.brewer.OrRd.length
  // );
  //`rgba(255,51,82,${opacity})`, //"#ff3352",
  return [
    // new Style({
    //   image: new CircleStyle({
    //     radius: 1,
    //     fill: new Fill({
    //       color: `rgba(255,51,82,1)`, //"#ff3352",
    //     }),
    //   }),
    // }),
    new Style({
      zIndex: 0,
      image: new CircleStyle({
        radius,
        fill: new Fill({
          color: "red",
        }),
      }),
    }),
  ];
};

export const addThermals = ({
  trackId,
  minAltGain = 300,
  minAveragePower = 5,
}) => {
  if (!STORE.igcs[trackId]) return;
  const { feature } = STORE.igcs[trackId];
  const trackVariations = getTrackVariations(feature);
  const thermals = getThermals({ trackVariations });

  if (!thermals) return;
  const features = thermals
    .filter(
      ({ altGain, averagePower }) =>
        altGain >= minAltGain || averagePower >= minAveragePower
    )
    .map(({ coordinate, altGain, maxPower, averagePower }) => {
      const feature = new Feature(new Point(coordinate));
      feature.setStyle(thermalPoint({ altGain, averagePower, maxPower }));
      return feature;
    });

  const thermalLayer = new VectorLayer({
    name: "thermals" + trackId,
    className: "thermalLayer",
    zIndex: 100,
    source: new VectorSource({
      features,
    }),
  });

  STORE.map.addLayer(thermalLayer);
};

export const removeThermals = (trackId) => {
  STORE.map.getLayers().forEach(function (layer) {
    if (layer && layer.get("name") === "thermals" + trackId) {
      STORE.map.removeLayer(layer);
    }
  });
};

export const removeAllThermals = () => {
  const layersToRemove = [];
  STORE.map.getLayers().forEach(function (layer) {
    if (layer && layer.get("name") && layer.get("name").includes("thermals")) {
      layersToRemove.push(layer);
    }
  });
  for (var i = 0; i < layersToRemove.length; i++) {
    STORE.map.removeLayer(layersToRemove[i]);
  }
};
