/* eslint-disable no-undef */
/* eslint-disable no-bitwise */
/* eslint-disable no-var */
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import isMobile from "ismobilejs";
import { fromLonLat } from "ol/proj";
import { STORE } from "..";
import store from "../store";
import { isSelectedPilots } from "./pilots";
// import tinycolor from 'tinycolor2'
dayjs.extend(minMax);

export const isMob = isMobile().apple.phone || isMobile().android.phone;

export const getDistInPixels = (c0, c1) => {
  const x = STORE.map.getPixelFromCoordinate(c0);
  const y = STORE.map.getPixelFromCoordinate(c1);
  return Math.hypot(x[0] - y[0], x[1] - y[1]);
};

export const getRadiusFromInput = (val) => {
  const r = val <= 10 ? val * 10 : (val - 9) * 100;
  return r * 1000;
};

export const stringToColour = function (str) {
  let hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (var j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 0xff;
    colour = colour + ("00" + value.toString(16)).substr(-2);
  }
  return colour;
  // const color = tinycolor(colour)
  // const isLight = color.isLight()
  // return isLight ? color.darken(5) : color;
};

export const getFormatFromUnix = (unix) => ({
  read: dayjs.unix(unix).format("DD/MM/YY"),
  readL: dayjs.unix(unix).format("DD/MM/YYYY"),
  input: dayjs.unix(unix).format("YYYY-MM-DD"),
});

export const getGliderCat = ({ GliderCat }) => {
  if (GliderCat === "39C1") {
    return "K";
  }
  return GliderCat.replace(/[0-9]/g, "");
};

// const getFFVLScore = (data) => {
//   const flight = IGCParser.parse(data, { lenient: true });
//   const result = solver(flight, scoring.FFVL).next().value;
//   //console.log({result});
//   const{opt:{scoring:{name:scoringName}}, scoreInfo:{distance,score}}=result
//   return{
//     scoringName,
//     distance,
//     score:score.toFixed(2)
//   }
// }

export const getFromTo = () => {
  const { dateEl, deltaEl } = store.state.dom;
  const today = dayjs().unix();
  const from = dayjs(dateEl).subtract(deltaEl, "day").unix();
  const to = dayjs(dateEl).add(deltaEl, "day").unix();
  const toMax = dayjs.min(dayjs().unix(), dayjs(to));
  const fromRead = getFormatFromUnix(from).read;
  const toRead = getFormatFromUnix(toMax).read;
  const isToAfterToday = dayjs(to).isAfter(today);
  return { from, to: toMax, fromRead, toRead, isToAfterToday };
};

export const gtagEvent = (name, opts = null) => {
  const host = window.location.hostname;
  console.log("##CAP##", { host });
  if (host !== "localhost") {
    if (opts) gtag("event", name, opts);
    else gtag("event", name);
  }
};

export const passFiltering = ({ takeOff, duration, flightType }) => {
  const { zone } = STORE;
  const { durationEl, flightTypeEl, zoneEl } = store.state.dom;
  const isInZone =
    zoneEl || isSelectedPilots()
      ? zone.intersectsCoordinate(fromLonLat(takeOff.lnglat))
      : true;
  const isOverMinDuration = !duration
    ? true
    : parseInt(duration) >= parseInt(durationEl);
  //const isGlider = gliderEl.includes(gliderCat);
  const isFlightType = flightTypeEl ? flightTypeEl === flightType : true;
  return isInZone && isOverMinDuration && isFlightType;
};

// export const setBlendMode = () => {
//   const { ctxBlend } = STORE
//   const checked = skywaysEl.checked
//   if(!ctxBlend) {
//     const tracksVectorLayerEl = document.getElementsByClassName('tracksVectorLayer')[0]
//     const canvas = tracksVectorLayerEl.getElementsByTagName('canvas')[0]
//     STORE.ctxBlend = canvas.getContext('2d')
//   }
//   STORE.ctxBlend.globalCompositeOperation = checked ? 'multiply' : 'normal'
//   STORE.map.render()
// }

export const scrollMobile = (el) => {
  window.scrollTo({
    top: el ? el.offsetHeight : document.body.scrollHeight,
    behavior: "smooth",
  });
};
