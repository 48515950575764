<template>
  <div class="whiteBox analytics" v-if="isAnalytics">
    <h3>Analyser</h3>
    <div class="tableWrapper">
      <div class="column head">
        <div class="pilotName"></div>
        <div v-if="isMobile">Date</div>
        <div v-if="isMobile">Aile</div>
        <div>Type</div>
        <div>Distance</div>
        <!-- <div>Points</div> -->
        <div>Vitesse</div>
        <div>Durée</div>
        <div>Altitude Max</div>
        <div>Vz Max</div>
        <div><i class="icon-loop grey"></i>Prospection</div>
        <div><i class="icon-right warm"></i>Cheminement</div>
        <div><i class="icon-up warm"></i>Thermique</div>
        <div><i class="icon-right cold"></i>Transition</div>
        <div></div>
      </div>
      <div class="colsWrapper">
        <AnalyticLine
          class="column"
          v-for="analytic in analytics"
          :key="analytic.trackId"
          :analytic="analytic"
          :is-mobile="isMobile"
        />
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import AnalyticLine from "./AnalyticLine.vue";
export default {
  components: { AnalyticLine },
  name: "Analytics",
  data: () => ({ isMobile: false }),
  computed: {
    analytics() {
      return this.$store.state.analyses;
    },
    isAnalytics() {
      return !!this.analytics.length;
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 760;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "auto",
      });
    }, 0);
  },
};
</script>
<style lang="scss">
.tableWrapper {
  display: flex;
  .column {
    padding: 0 8px;
    border-left: 1px solid #eee;
    &.head {
      border: none;
      padding-left: 0;
    }
  }
  .colsWrapper {
    display: flex;
    overflow: scroll;
  }
  .column > div {
    height: 20px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    // justify-content: space-between;
    &.pilotName {
      margin-bottom: 5px;
    }
  }

  .head {
    font-size: 12px;
  }
  .head i {
    margin-right: 5px;
  }
}

.icon {
  font-size: 15px;
}
</style>
