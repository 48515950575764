import { click } from "ol/events/condition";
import { extend } from "ol/extent";
import { DragRotate, Select } from "ol/interaction";
import { Layer } from "ol/layer";
import Map from "ol/Map";
import { fromLonLat, toLonLat } from "ol/proj";
//import OSM, { ATTRIBUTION } from "ol/source/OSM";
import Source from "ol/source/Source";
import View from "ol/View";
import { STORE } from "..";
import { config } from "../config";
import { isMob } from "./helpers";
import { markerOverStyle, setMarkerInfo } from "./markers";
import {
  isSelectedPilot,
  isSelectedPilots,
  selectPilot,
  unselectPilot,
  updatePilotsInMap,
} from "./pilots";
import { displaySnap } from "./snap";
import { trackOverStyle, trackUnselectedStyle } from "./tracks";
import { params, PKEYS, setURLParams } from "./urlParams";

const createMapBox = () => {
  const key = "aJcoHh2v0Z8biLw5YfUX";
  //https://cloud.maptiler.com/maps/
  const style = "outdoor"; // outdoor | topographique | topo | hybrid
  const mbMap = new mapboxgl.Map({
    style: "https://api.maptiler.com/maps/" + style + "/style.json?key=" + key,
    attributionControl: false,
    boxZoom: false,
    center: [-98.8, 37.9],
    container: "map",
    doubleClickZoom: false,
    dragPan: false,
    dragRotate: false,
    interactive: false,
    keyboard: false,
    pitchWithRotate: false,
    scrollZoom: false,
    touchZoomRotate: false,
  });

  const mbLayer = new Layer({
    render: function (frameState) {
      const canvas = mbMap.getCanvas();
      const viewState = frameState.viewState;

      const visible = mbLayer.getVisible();
      canvas.style.display = visible ? "block" : "none";
      canvas.style.position = "absolute";

      const opacity = mbLayer.getOpacity();
      canvas.style.opacity = opacity;

      // adjust view parameters in mapbox
      const rotation = viewState.rotation;
      mbMap.jumpTo({
        center: toLonLat(viewState.center),
        zoom: viewState.zoom - 1,
        bearing: (-rotation * 180) / Math.PI,
        animate: false,
      });

      // cancel the scheduled update & trigger synchronous redraw
      // see https://github.com/mapbox/mapbox-gl-js/issues/7893#issue-408992184
      // NOTE: THIS MIGHT BREAK IF UPDATING THE MAPBOX VERSION
      if (mbMap._frame) {
        mbMap._frame.cancel();
        mbMap._frame = null;
      }
      mbMap._render();

      return canvas;
    },
    source: new Source({
      attributions: [
        '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a>',
        '<a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      ],
    }),
  });

  return mbLayer;
};

export const buildMap = () => {
  const mbLayer = createMapBox();
  const map = new Map({
    layers: [
      // new TileLayer({
      //   source: new OSM({
      //     attributions: [
      //       // 'All maps © <a href="https://www.opencyclemap.org/">OpenCycleMap</a>',
      //       ATTRIBUTION,
      //     ],
      //     url:
      //       "https://{a-c}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png" +
      //       // 'https://tile.thunderforest.com/outdoors/${z}/${x}/${y}.png' +
      //       "?apikey=6f11b0ef72ca44349e81abbbb9f525cb",
      //   }),
      // }),

      // new MapboxVector({
      //   styleUrl: "mapbox://styles/mapbox/outdoors-v11", // satellite-v9 | outdoors-v11 | light-v10
      //   accessToken:
      //     "pk.eyJ1Ijoic2ViYXAiLCJhIjoiY2tzMmI1OGlvMjF2cTMwcWo4OHliY2ZwNCJ9.ht2u9WUCqvyBHc8vf0iudw",
      // }),
      mbLayer,
    ],
    target: "map",
    view: new View({
      center: fromLonLat(config.zoneLngLat),
      zoom: config.mapZoom,
      //minZoom: config.mapZoom,
    }),
  });

  STORE.map = map;
};

export const getMapExt = () => {
  const ext = STORE.map.getView().calculateExtent(STORE.map.getSize());
  return ext;
};

const manageInteractions = () => {
  const interactions = STORE.map.getInteractions().getArray();
  //console.log({ interactions });
  interactions.some(function (interaction) {
    if (
      interaction instanceof DragRotate
      // (interaction instanceof DoubleClickZoom && isMob) ||
      // interaction instanceof PinchRotate ||
      // interaction instanceof PinchZoom
    ) {
      interaction.setActive(false);
    }
  });
};

const initListeners = () => {
  let hasMapMoveOnInit = false; // prevent map move trigger on map init
  let hoveredFeature = null;

  STORE.map.on("moveend", function () {
    const { map } = STORE;

    if (hasMapMoveOnInit) {
      const mapZoom = map.getView().getZoom();
      const mapCenter = map.getView().getCenter();
      const pmz = params.get(PKEYS.mapZoom);
      const pmc = params.get(PKEYS.mapCenter);
      const pmzOld = pmz && parseFloat(pmz);
      const pmcOld = pmc && pmc.split(",").map((v) => parseFloat(v));

      if (mapZoom !== pmzOld) {
        setURLParams({ key: PKEYS.mapZoom, value: mapZoom });
      }
      if (mapCenter !== pmcOld) {
        setURLParams({ key: PKEYS.mapCenter, value: mapCenter });
      }
      if (!isSelectedPilots()) {
        updatePilotsInMap();
      }

      //gtagEvent("mapMove");
    }
    hasMapMoveOnInit = true;
  });

  STORE.map.on("pointermove", function (evt) {
    if (evt.dragging || isMob) {
      return;
    }

    const { map } = STORE;

    if (STORE.countInZone > 0) {
      const coordinate = map.getEventCoordinate(evt.originalEvent);
      displaySnap({ coordinate });
    }
    // if(isZoneGrabber) {
    //   STORE.point = null
    //   STORE.line = null
    // }

    if (hoveredFeature !== null) {
      const featureProps = hoveredFeature.getProperties();
      const { type } = featureProps;
      if (type === "track") {
        const { id: trackId } = featureProps;
        if (isSelectedPilots() && !isSelectedPilot(trackId)) {
          hoveredFeature.setStyle(trackUnselectedStyle);
        } else {
          hoveredFeature.setStyle(null);
        }
      } else if (type === "site") {
        STORE.point = null;
        STORE.line = null;
        hoveredFeature.setStyle(null);
        map.render();
      }
      hoveredFeature = null;
    }

    STORE.map.forEachFeatureAtPixel(evt.pixel, function (f) {
      const { type, ...props } = f.getProperties();
      if (type === "track") {
        f.setStyle(trackOverStyle);
        hoveredFeature = f;
      }
      if (type === "site") {
        setMarkerInfo(props);
        f.setStyle(markerOverStyle);
        hoveredFeature = f;
      }
      return true;
    });
  });

  // STORE.map.on('singleclick', function (e) {
  //   const { coordinate } = e
  //   if(isMob) {
  //     infoWrapperEl.style.display='block'
  //     displaySnap({ coordinate })
  //   }
  // })

  const select = new Select({
    condition: function (mapBrowserEvent) {
      return click(mapBrowserEvent); // && altKeyOnly(mapBrowserEvent);
    },
  });

  STORE.map.addInteraction(select);

  select.on("select", function (e) {
    // console.log("select",e.mapBrowserEvent.coordinate);
    // console.log(e.selected[0].getGeometry())
    // map.getView().fit(markerSource.getExtent());
    // console.log('select',e.selected.length);
    const trackId = e.selected.length ? e.selected[0].id_ : null;

    if (trackId) {
      if (isSelectedPilot(trackId)) {
        unselectPilot({ trackId });
      } else {
        selectPilot({ trackId });
      }
    }
  });
};

// export const mapFitTrack = ({ feature }) => {
//   const padding = isMob? 30 : 200
//   STORE.map.getView().fit(feature.getGeometry().getExtent(),{ padding:[ padding, padding, padding, padding ] })
// }

export const mapFitTracks = (trackIds) => {
  const geos = trackIds.map((trackId) =>
    STORE.igcs[trackId].feature.getGeometry()
  );
  const extent = geos[0].getExtent();
  for (let index = 1; index < geos.length; index++) {
    extend(extent, geos[index].getExtent());
  }
  const padding = isMob ? 30 : 200;
  STORE.map
    .getView()
    .fit(extent, { padding: [padding, padding, padding, padding] });
};

export const mapFitZone = () => {
  const padding = isMob ? 30 : 200;
  STORE.map.getView().fit(STORE.zone.getExtent(), {
    padding: [padding, padding, padding, padding],
  });
};

export const initMap = () => {
  buildMap();
  manageInteractions();
  initListeners();
};
