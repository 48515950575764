<template>
  <div>
    <div class="dates ctrl">
      <label>Autour du</label>
      <input type="date" id="date" v-model="dateEl" />
    </div>
    <div class="ctrl">
      <label>Plus ou moins</label>
      <select class="delta" v-model="deltaEl">
        <option value="0">jour exact</option>
        <option value="1">1 jour</option>
        <option value="3">3 jours</option>
        <option value="7">7 jours</option>
        <option value="15">15 jours</option>
        <option value="30">1 mois</option>
        <option value="60">3 mois</option>
        <option value="180">6 mois</option>
        <option value="365">1 an</option>
        <option value="1825">5 ans</option>
        <option value="3650">10 ans</option>
      </select>
    </div>
    <div class="ctrl">
      <label for="distance" class="label">Distance de</label>
      <select id="distanceMin" v-model="distanceMinEl">
        <option
          v-for="distance in DISTANCES.filter(
            (d) => d !== DISTANCES[DISTANCES.length - 1]
          )"
          :value="distance"
          :key="'min' + distance"
        >
          {{ distance === 999 ? "∞" : distance }}
        </option>
      </select>
      à
      <select id="distanceMax" v-model="distanceMaxEl">
        <option
          v-for="distance in DISTANCES.filter((d) => d !== DISTANCES[0])"
          :value="distance"
          :key="'max' + distance"
        >
          {{ distance === 999 ? "∞" : distance }}
        </option>
      </select>
      km
    </div>

    <div class="ctrl">
      <label>Département</label>
      <select class="departement" v-model="departementEl">
        <option value="">Tous</option>
        <option value="others">Monde 🌏</option>
        <option v-for="dep in DEPARTEMENTS" :key="dep.value" :value="dep.value">
          {{ dep.label }}
        </option>
      </select>
    </div>

    <div class="ctrl">
      <label class="label">Type d'aile</label>
      <div class="gliderCats">
        <label v-for="gliderCat in gliderCats" :key="gliderCat.value">
          {{ gliderCat.label }}
          <input
            type="checkbox"
            v-model="gliderEl"
            :id="gliderCat.value"
            :value="gliderCat.value"
          />
        </label>
      </div>
    </div>

    <div class="ctrl submitWrapper">
      <SimpleTooltip info="Période précédente">
        <button
          id="prev"
          :disabled="isLoadingFlights"
          @click="onPrevClick"
          v-if="isMoreSearch"
        >
          <span class="revert">➜</span>
        </button>
      </SimpleTooltip>
      <button id="submit" :disabled="isLoadingFlights" @click="onSubmit">
        Rechercher
      </button>
      <SimpleTooltip info="Période suivante">
        <button
          id="next"
          :disabled="isLoadingFlights || isToAfterToday"
          @click="onNextClick"
          v-if="isMoreSearch"
        >
          ➜
        </button>
      </SimpleTooltip>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { search } from "../controllers/api";
import { getFromTo, gtagEvent } from "../controllers/helpers";
import { MUTATIONS } from "../store";
import { departements } from "../config";
import SimpleTooltip from "./vuetify/SimpleTooltip.vue";
import { updateZone } from "../controllers/zone";
//import { getMinDistanceValue } from "../controllers/urlParams";

const GLIDER_CATS = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "K", value: "K" },
];

export default {
  name: "Search",
  data: function () {
    return { departement: "", gliderCats: [] };
  },
  components: { SimpleTooltip },
  watch: {
    "$store.state.dom.distanceMinEl": function (distanceMin) {
      const distanceMax = this.$store.state.dom.distanceMaxEl;
      //console.log("##CAP##", { distanceMin, distanceMax });
      if (distanceMin >= distanceMax) {
        this.$store.commit(MUTATIONS.setDomValues, {
          distanceMaxEl: this.DISTANCES[this.DISTANCES.length - 1],
        });
      }
    },
    "$store.state.dom.distanceMaxEl": function (distanceMax) {
      const distanceMin = this.$store.state.dom.distanceMinEl;
      if (distanceMax <= distanceMin) {
        //console.log("##CAP##", { distanceMin, distanceMax });
        this.$store.commit(MUTATIONS.setDomValues, {
          distanceMinEl: this.DISTANCES[0],
        });
      }
    },
  },
  methods: {
    onSubmit() {
      search();
      gtagEvent("search", { submit: true });
    },
    onPrevNextClick(type) {
      const { deltaEl, dateEl } = this.$store.state.dom;
      const newDate = dayjs(dateEl)
        [type === "prev" ? "subtract" : "add"](deltaEl * 2 + 1, "day")
        .format("YYYY-MM-DD");
      this.$store.commit(MUTATIONS.setDomValues, { dateEl: newDate });
      search();
      gtagEvent("search", { [type]: newDate });
    },
    onPrevClick() {
      this.onPrevNextClick("prev");
    },
    onNextClick() {
      this.onPrevNextClick("next");
    },
  },
  computed: {
    isToAfterToday() {
      return getFromTo().isToAfterToday;
    },
    isLoadingFlights() {
      return this.$store.state.isLoadingFlights;
    },
    isMoreSearch() {
      return this.$store.state.isMoreSearch;
    },
    dateEl: {
      get() {
        return this.$store.state.dom.dateEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setIsMoreSearch, false);
        this.$store.commit(MUTATIONS.setDomValues, { dateEl: value });
        gtagEvent("search", { date: value });
      },
    },
    deltaEl: {
      get() {
        return this.$store.state.dom.deltaEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setIsMoreSearch, false);
        this.$store.commit(MUTATIONS.setDomValues, { deltaEl: value });
        localStorage.delta = value;
        gtagEvent("search", { delta: value });
      },
    },
    departementEl: {
      get() {
        return this.$store.state.dom.departementEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setIsMoreSearch, false);
        this.$store.commit(MUTATIONS.setDomValues, { departementEl: value });
        localStorage.departement = value;
        gtagEvent("search", { departement: value });
      },
    },
    distanceMinEl: {
      get() {
        return this.$store.state.dom.distanceMinEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setDomValues, { distanceMinEl: value });
        localStorage.distanceMin = value;
        gtagEvent("search", { distanceMin: value });
      },
    },
    distanceMaxEl: {
      get() {
        return this.$store.state.dom.distanceMaxEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setDomValues, { distanceMaxEl: value });
        localStorage.distanceMax = value;
        gtagEvent("search", { distanceMax: value });
      },
    },
    gliderEl: {
      get() {
        return this.$store.state.dom.gliderEl;
      },
      set(value) {
        this.$store.commit(MUTATIONS.setDomValues, { gliderEl: value });
        const gliderStr = value.join(",");
        updateZone();
        localStorage.gliderCat = gliderStr;
        gtagEvent("search", { gliderCat: gliderStr });
      },
    },
  },
  created() {
    this.gliderCats = GLIDER_CATS;
    this.DISTANCES = [50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 400, 999];
    this.DEPARTEMENTS = departements;
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables";

.delta {
  //padding: 0;

  option {
    text-align: left;
    //padding: 0;
  }
}

.dates {
  input::-webkit-calendar-picker-indicator {
    margin-left: 0px;
  }
  input#date {
    @media (min-width: $screen-md) {
      // tablet
      width: 130px;
    }
  }
}

.ctrl {
  display: flex;
  align-items: center;
  label {
    min-width: 100px;
    display: block;
  }
}

.departement {
  font-size: 11px;
}

.symb {
  font-size: 14px;
  padding: 5px;
}

.submitWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  button {
    display: block;
    flex: 1;
    height: 40px;
    width: 40px;
    font-weight: bold;
  }
  #prev,
  #next {
    font-size: 13px;
  }
  #submit {
    /* background: lightsteelblue;
    padding: 5px;
    border: none; */
    height: 40px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 5;
  }
  #prev {
    margin-right: 10px;
  }
  #next {
    margin-left: 10px;
  }
  .revert {
    display: block;
    transform: rotate(180deg);
  }
}
.gliderCats {
  display: flex;
  align-items: center;
  justify-content: center;
  label {
    min-width: auto;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    font-weight: bold;
  }
}
</style>
