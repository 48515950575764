<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>
    <span>{{ info }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "SimpleTooltip",
  props: ["info"],
};
</script>
<style scoped lang="scss"></style>
