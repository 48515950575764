<template>
  <div class="root">
    <div v-if="isSite">
      <strong>{{ info.name }}</strong> ({{ info.takeoff_altitude }} m) <br />
      <span>Orientations :</span> {{ orientations }}<br />
      <span>Coordonnées :</span> {{ info.lat }},{{ info.lng }}
    </div>
    <div v-if="isTrack">
      <div>
        <strong>{{ info.pilotFullName }}</strong> le {{ info.dateShort }}
      </div>
      <div>
        <strong>Déco</strong> : {{ info.takeoffName }} ({{ info.departement }})
      </div>
      <div><strong>Attéro</strong> : {{ info.landingName }}</div>
      <div>
        <strong>Aile</strong> : {{ info.gliderBrand }} {{ info.gliderName }} (
        {{ info.gliderCat }} )
      </div>
      <div><strong>Distance</strong> : {{ info.distance }} km</div>
      <div><strong>Durée</strong> : {{ info.duration }}</div>
      <div><strong>Vitesse</strong> : {{ info.speed }} km/h</div>
      <div><strong>Plaf max</strong> : {{ info.altitudeMax }} m</div>
      <div><strong>Vz max</strong> : {{ info.vzMax }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Info",
  computed: {
    info() {
      return this.$store.state.info;
    },
    isInfo() {
      return this.isTrack || this.isSite;
    },
    isTrack() {
      return this.info.type === "track";
    },
    isSite() {
      return this.info.type === "site";
    },
    orientations() {
      return Object.entries(this.info.orientations)
        .map(([key, value]) => (value ? key : null))
        .filter((o) => o)
        .join(",");
    },
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/styles/variables";
.root {
  line-height: 20px;
  border: 2px solid #fff;
  width: auto;
  pointer-events: none;

  @media (min-width: $screen-md) {
    display: block;
  }
}
</style>
