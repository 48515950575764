<template>
  <div>
    <BackButton v-if="isMobile && isSelectedPilots" />
    <aside id="analyseWrapper">
      <BackButton v-if="!isMobile && isSelectedPilots" />
      <div
        class="scrollMore whiteBox"
        v-if="isMobile && isFlights"
        @click="toggleScroll"
      >
        <span class="arrow" :class="{ up: !isBottom }">▾</span>
      </div>
      <div
        id="result"
        class="whiteBox result"
        v-show="!isSelectedPilots"
        ref="result"
      >
        <Result />
      </div>
      <div
        id="replay"
        class="whiteBox replay"
        v-if="isSelectedPilots && isMobile"
        ref="replay"
      >
        <Replay />
      </div>
      <Analytics v-if="isSelectedPilots">
        <div class="replay" v-if="!isMobile">
          <Replay />
        </div>
      </Analytics>
      <Pilots />
      <div class="whiteBox" v-if="isInfo && !isMobile">
        <Info />
      </div>
    </aside>
  </div>
</template>

<script>
import Analytics from "./Analytics.vue";
import BackButton from "./BackButton.vue";
import Info from "./Info.vue";
import Replay from "./Replay.vue";
import Pilots from "./Pilots.vue";
import Result from "./Result.vue";

export default {
  components: {
    Pilots,
    Replay,
    Info,
    BackButton,
    Analytics,
    Result,
  },
  name: "Analyse",
  computed: {
    isSelectedPilots() {
      return !!this.$store.state.selectedPilots.length;
    },
    isFlights() {
      return !!this.$store.state.totalFlights;
    },
    isLoadingFlights() {
      return this.$store.state.isLoadingFlights;
    },
    info() {
      return this.$store.state.info;
    },
    isInfo() {
      return this.isTrack || this.isSite;
    },
    isTrack() {
      return this.info.type === "track";
    },
    isSite() {
      return this.info.type === "site";
    },
  },
  data: () => ({ isMobile: false, isBottom: false }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.isBottom = true;
      } else {
        this.isBottom = false;
      }
    };
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 760;
    },
    toggleScroll() {
      const ref = this.$refs.result || this.$refs.replay;
      const startAt = ref ? ref.offsetHeight : 0;
      const top = this.isBottom ? startAt : document.body.scrollHeight;
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables";
#analyseWrapper {
  margin-bottom: 1px;
  position: relative;
  filter: drop-shadow(0 0 4px rgba(#000, 0.4));
  background: #eee;

  @media (min-width: $screen-md) {
    filter: none;
    background: none;
    font-size: inherit;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 320px;
    display: flex;
    flex-direction: column;
  }
}
.result {
  padding: 0;
  overflow: hidden;
}
.quickaccess__wrapper {
  //padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .quickaccess {
    margin-left: 10px;
  }
}

.replay {
  margin-top: 1px;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    flex: 1;
  }
  @media (min-width: $screen-md) {
    margin-top: 15px;
    height: auto;
  }
}

.scrollMore {
  text-align: center;
  width: 80px;
  height: 40px;
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .arrow {
    // transform-origin: center;
    // transition: transform 0.4s ease-in-out;
    font-size: 18px;
    &.up {
      display: block;
      transform: rotate(180deg);
    }
  }
}
</style>
