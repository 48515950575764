<template>
  <div
    class="pilot"
    :class="{ selected: isSelected }"
    @click="togglePilot"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="color" :style="{ backgroundColor: flight.color }"></div>
    <div class="content">
      <span class="info">{{ flight.dateShort }}</span>
      <span class="info extra" v-if="extraFull">{{ extraFull }}</span>
      <!-- <span class="info">{{ flight.glider }}</span>
      <span class="info">{{ flight.km }}</span> -->
      <!-- <span class="info mv" v-if="isMarcheVol">MV</span> -->
      <span class="name">{{ flight.pilotFullName }}</span>
    </div>
  </div>
</template>
<script>
import { STORE } from "..";
import { isMob } from "../controllers/helpers";
import { onPilotOut, onPilotOver, togglePilot } from "../controllers/pilots";

export default {
  name: "Pilot",
  props: ["trackId", "extra"],
  computed: {
    isSelected() {
      return this.$store.state.selectedPilots.includes(this.trackId);
    },
    flight() {
      return STORE.flights[this.trackId];
    },
    extraFull() {
      const excludes = ["lat", "date", "pilotLastName"];
      if (excludes.includes(this.extra)) return null;
      return this.flight[this.extra];
    },
  },
  methods: {
    togglePilot() {
      togglePilot(this.trackId);
    },
    onMouseEnter() {
      if (!isMob) {
        onPilotOver(this.trackId);
      }
    },
    onMouseLeave() {
      if (!isMob) {
        onPilotOut(this.trackId);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pilot {
  cursor: pointer;
  padding: 3px 5px;
  padding-bottom: 4px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;

  &:hover {
    background: #eee;
  }

  .color {
    flex-shrink: 0;
  }

  &.selected {
    background: blue;
    color: #fff;
    position: relative;

    &:after {
      content: "×";
      color: blue;
      display: block;
      background: #fff;
      border-radius: 5px;
      height: 16px;
      line-height: 14px;
      width: 16px;
      text-align: center;
      position: absolute;
      right: 4px;
    }
    .color {
      border: 1px solid #fff;
    }
    .content {
      overflow: hidden;
    }
  }

  &:hover,
  &.selected {
    border-radius: 8px;
  }

  .info {
    min-width: 0;
    color: #111;
    background: #eee;
    padding: 2px 3px;
    border-radius: 3px;
    font-size: 12px;
    margin-right: 5px;

    &.mv {
      background: green;
      color: #fff;
    }
    &.extra {
      font-weight: bold;
    }
  }

  div {
    min-width: 0;
    pointer-events: none;
  }
}
</style>
