import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const MUTATIONS = {
  setDomValues: "setDomValues",
  setLoadingFlights: "setLoadingFlights",
  setLoadingIgcs: "setLoadingIgcs",
  setLoadedIgcs: "setLoadedIgcs",
  setTotalFlights: "setTotalFlights",
  setFlights: "setFlights",
  setLoadingTakeoffs: "setLoadingTakeoffs",
  setIsMoreSearch: "setIsMoreSearch",
  selectPilot: "selectPilot",
  unselectPilot: "unselectPilot",
  clearSelectedPilots: "clearSelectedPilots",
  addPilot: "addPilot",
  clearPilots: "clearPilots",
  setInfo: "setInfo",
  setExportLink: "setExportLink",
  setTakeoffs: "setTakeoffs",
  setTime: "setTime",
  addAnalyse: "addAnalyse",
  removeAnalyse: "removeAnalyse",
  clearAnalyses: "clearAnalyses",
};

export default new Vuex.Store({
  state: {
    dom: {
      durationEl: "",
      dateEl: "",
      deltaEl: "",
      departementEl: "",
      gliderEl: [],
      flightTypeEl: [],
      distanceMinEl: "",
      distanceMaxEl: "",
      sitesEl: "46.89,1.90,800",
      pratiqueEl: "All",
      takeoffsEl: false,
      zoneEl: false,
      thermalsEl: false,
      tracksEl: false,
    },
    data: {
      takeoffs: [],
      flights: [],
    },
    isLoadingFlights: false,
    isLoadingIgcs: false,
    loadedIgcs: 0,
    totalFlights: 0,
    isLoadingTakeoffs: false,
    isMoreSearch: false,
    selectedPilots: [],
    analyses: [],
    pilots: [],
    info: {},
    exportLink: "#",
    time: {
      start: Infinity,
      stop: -Infinity,
      duration: 0,
    },
  },
  mutations: {
    [MUTATIONS.setDomValues](state, payload) {
      state.dom = { ...state.dom, ...payload };
    },
    [MUTATIONS.setLoadingFlights](state, payload) {
      state.isLoadingFlights = payload;
    },
    [MUTATIONS.setLoadingTakeoffs](state, payload) {
      state.isLoadingTakeoffs = payload;
    },
    [MUTATIONS.setIsMoreSearch](state, payload) {
      state.isMoreSearch = payload;
    },
    [MUTATIONS.selectPilot](state, payload) {
      state.selectedPilots = [...state.selectedPilots, payload];
    },
    [MUTATIONS.unselectPilot](state, payload) {
      state.selectedPilots = state.selectedPilots.filter(
        (id) => id !== payload
      );
    },
    [MUTATIONS.clearSelectedPilots](state) {
      state.selectedPilots = [];
    },
    [MUTATIONS.addPilot](state, payload) {
      state.pilots = [...state.pilots, payload];
    },
    [MUTATIONS.clearPilots](state) {
      state.pilots = [];
    },
    [MUTATIONS.setInfo](state, payload) {
      state.info = payload;
    },
    [MUTATIONS.setExportLink](state, payload) {
      state.exportLink = payload;
    },
    [MUTATIONS.setTakeoffs](state, payload) {
      state.data.takeoffs = payload;
    },
    [MUTATIONS.setFlights](state, payload) {
      state.data.flights = payload;
    },
    [MUTATIONS.setTime](state, payload) {
      state.time = payload;
    },
    [MUTATIONS.addAnalyse](state, payload) {
      state.analyses = [...state.analyses, payload];
    },
    [MUTATIONS.removeAnalyse](state, payload) {
      state.analyses = state.analyses.filter((a) => a.trackId !== payload);
    },
    [MUTATIONS.clearAnalyses](state) {
      state.analyses = [];
    },
    [MUTATIONS.setLoadingIgcs](state, payload) {
      state.isLoadingIgcs = payload;
    },
    [MUTATIONS.setLoadedIgcs](state, payload) {
      state.loadedIgcs = payload;
    },
    [MUTATIONS.setTotalFlights](state, payload) {
      state.totalFlights = payload;
    },
  },
  actions: {},
  getters: {
    sortedPilots(state) {
      return state.pilots.sort(function (a, b) {
        const ax = parseFloat(a.lat);
        const bx = parseFloat(b.lat);
        return bx - ax;
      });
    },
    flightTypes(state) {
      const ft = state.data.flights
        .map(({ flightType }) => flightType)
        .sort((a, b) => (a < b) - (a > b));
      return [...new Set(ft)];
    },
  },
  modules: {},
});
