<template>
  <div v-if="isPilots" @input="onChange($event)" @click="onClick" :key="key">
    <div class="rangectrl">
      <strong>{{ currentTime || start }}</strong>
      <input id="time" type="range" value="0" step="1" min="0" max="1000" />
      <strong>{{ stop }}</strong>
      <!-- <button id="resetTimeBtn">✕</button> -->
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { STORE } from "..";
import { addTimePoint } from "../controllers/replay";
import { gtagEvent } from "../controllers/helpers";

export default {
  name: "Replay",
  data() {
    return {
      currentTime: null,
    };
  },
  computed: {
    key() {
      return this.start + this.stop;
    },
    isPilots() {
      return !!this.$store.state.pilots.length;
    },
    time() {
      return this.$store.state.time;
    },
    start() {
      return this.formatTime(this.time.start);
    },
    stop() {
      return this.formatTime(this.time.stop);
    },
  },
  watch: {
    start(nv, pv) {
      if (nv && nv !== pv) {
        this.currentTime = null;
      }
    },
    stop(nv, pv) {
      if (nv && nv !== pv) {
        this.currentTime = null;
      }
    },
  },
  methods: {
    formatTime(unix) {
      return dayjs.unix(unix).format("HH[h]mm");
    },
    onChange(e) {
      const { tracksVectorSource, map } = STORE;
      const value = parseFloat(e.target.value) / 1000;
      const m = this.time.start + this.time.duration * value;
      this.currentTime = this.formatTime(m);
      tracksVectorSource.forEachFeature(function (feature) {
        addTimePoint({ feature, m });
      });
      map.render();
    },
    onClick() {
      gtagEvent("analyse", { replay: true });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables";
</style>
