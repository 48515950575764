<template>
  <div @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="data pilotName">
      <SimpleTooltip :info="flight.pilotFullName">
        <div class="pilotNameColor" :style="{ background: flight.color }">
          {{ flight.pilotInit }}
        </div>
      </SimpleTooltip>
    </div>
    <div class="data" v-if="isMobile">{{ flight.dateShort }}</div>
    <div class="data" v-if="isMobile">
      {{ flight.gliderName }} ( {{ flight.gliderCat }} )
    </div>
    <div class="data">{{ flight.flightType }}</div>
    <div class="data">{{ flight.distance }} km</div>
    <!-- <div class="data">{{ flight.points }} pts</div> -->
    <div class="data">{{ flight.speed }} km/h</div>
    <div class="data">{{ flight.duration }}</div>
    <div class="data">{{ flight.altitudeMax }} m</div>
    <div class="data">{{ flight.vzMax }}</div>
    <div class="data">{{ analytic.prospection }}%</div>
    <div class="data">{{ analytic.cheminement }}%</div>
    <div class="data">{{ analytic.thermique }}%</div>
    <div class="data">{{ analytic.transition }}%</div>
    <div class="data">
      <a :href="flight.flightUrl" target="_blank">+ infos</a>
    </div>
  </div>
</template>
<script>
import { STORE } from "..";
import { isMob } from "../controllers/helpers";
import { onPilotOut, onPilotOver } from "../controllers/pilots";
import SimpleTooltip from "./vuetify/SimpleTooltip.vue";
export default {
  name: "AnalyticLine",
  props: ["analytic", "isMobile"],
  components: { SimpleTooltip },
  data() {
    return {
      isLoadingFFVL: true,
      ffvl_type: null,
      ffvl_distance: null,
    };
  },
  computed: {
    trackId() {
      return this.analytic.trackId;
    },
    flight() {
      return STORE.flights[this.trackId];
    },
  },
  methods: {
    onMouseEnter() {
      if (!isMob) {
        onPilotOver(this.trackId);
      }
    },
    onMouseLeave() {
      if (!isMob) {
        onPilotOut(this.trackId);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.data {
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
}
</style>
